<template>
  <bb-application-page
    :form-props="{
      uppercaseButtons: false,
      errorText: translations.errors.errorText,
      submitText: translations.submitText,
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="onSubmit"
  >
    <template #header>
      <bb-public-header
        :title="headingText"
        icon="client-personal"
      />
    </template>
    <div class="formOrder" ref="form">
      <div :style="orderStyle('dateOfBirth')">
        <bb-input
          v-if="showField('dateOfBirth')"
          v-model="form.dateOfBirth"
          v-validate="getValidationRules('dateOfBirth')"
          :placeholder="dateFormatPlaceholder"
          :label="translations.dateOfBirth"
          :data-vv-as="translations.dateOfBirth"
          name="dateOfBirth"
        />
      </div>
      <div :style="orderStyle('placeOfBirth')">
        <bb-select
          v-if="showField('placeOfBirth')"
          v-model="form.placeOfBirth"
          v-validate="getValidationRules('placeOfBirth')"
          :disabled="isDisabled('placeOfBirth')"
          :options="countryOptionsWithExcludedStateless"
          :label="translations.placeOfBirth"
          :data-vv-as="translations.placeOfBirth"
          name="placeOfBirth"
        />
      </div>
      <div :style="orderStyle('taxIdNumber')">
        <bb-masked-input
          name="taxIdNumber"
          v-validate="TIN.validationRules"
          v-if="TIN.isVisible && TIN.isMaskUsed"
          :data-vv-as="translations.taxIdNumber"
          :label="translations.taxIdNumber"
          v-model="form.taxIdNumber"
          :mask="TIN.mask"
        />
        <bb-input
          name="taxIdNumber"
          v-validate="TIN.validationRules"
          v-if="TIN.isVisible && !TIN.isMaskUsed"
          :data-vv-as="translations.taxIdNumber"
          :label="translations.taxIdNumber"
          v-model="form.taxIdNumber"
        />
      </div>
      <div :style="orderStyle('taxResidency')">
        <bb-select
          v-if="taxResidency.isVisible"
          v-model="form.taxResidency"
          v-validate="taxResidency.validationRules"
          :disabled="isDisabled('taxResidency')"
          :options="countryOptionsWithExcludedStateless"
          :label="translations.taxResidency"
          :data-vv-as="translations.taxResidency"
          :helpActive="true"
          :helpText="translations.taxResidencyHelp"
          name="taxResidency"
        />
      </div>
      <div :style="orderStyle('fieldOfActivity')">
        <bb-select
          v-if="showField('fieldOfActivity')"
          v-model="form.fieldOfActivity"
          v-validate="getValidationRules('fieldOfActivity')"
          :options="fieldOfActivity.options"
          :label="translations.fieldOfActivity"
          :data-vv-as="translations.fieldOfActivity"
          name="fieldOfActivity"
        />
      </div>
      <div :style="orderStyle('customFieldOfActivity')">
        <bb-input
          v-if="fieldOfActivity.isClarificationRequired"
          v-model="form.customFieldOfActivity"
          v-validate="customFieldOfActivity.validationRules"
          :label="translations.otherActivity"
          :data-vv-as="translations.otherActivity"
          name="customFieldOfActivity"
        />
      </div>
      <div :style="orderStyle('citizenship')">
        <bb-select
          v-if="showField('citizenship')"
          v-model="form.citizenship"
          v-validate="getValidationRules('citizenship')"
          :disabled="isDisabled('citizenship')"
          :options="countryOptions"
          :label="translations.citizenship"
          :data-vv-as="translations.citizenship"
          name="citizenShip"
          @change="onCitizenshipChange"
        />
      </div>
      <div :style="orderStyle('dualCitizenship')">
        <bb-checkbox
          v-if="showField('dualCitizenship')"
          v-model="hasDualCitizenship"
          name="hasDualCitizenship"
        >
          {{translations.haveDualCitizenship}}
        </bb-checkbox>
        <bb-select
          v-if="hasDualCitizenship"
          v-model="form.dualCitizenship"
          v-validate="getValidationRules('dualCitizenship')"
          :options="dualCitizenshipCountryOptions"
          :label="translations.dualCitizenship"
          :data-vv-as="translations.dualCitizenship"
          name="dualCitizenship"
        />
      </div>
      <div :style="orderStyle('isPep')">
        <bb-radio
          v-model="form.isPep"
          v-validate="PEP.validationRules"
          data-vv-validate-on="change"
          v-if="PEP.isVisible"
          :label="PEP.label"
          :options="PEP.options"
          class="m-t-40 p-r-15"
          :data-vv-as="translations.pep"
          :helpActive="true"
          :helpText="pepHelpText"
          name="isPep"
        />
      </div>
      <div :style="orderStyle('isPepReason')">
        <bb-textarea
          class="is-pep-reason"
          v-if="showField('isPepReason')"
          v-model="form.isPepReason"
          v-validate="getValidationRules('isPepReason')"
          :label="translations.isPepReason"
          :data-vv-as="translations.isPepReason"
          name="isPepReason"
          framed
        />
      </div>
    </div>
    <slot name="footer"></slot>
  </bb-application-page>
</template>

<script>
import {
  BbApplicationPage,
  BbPublicHeader,
  BbInput,
  BbCheckbox,
  BbRadio,
  BbSelect,
  BbMaskedInput,
  BbTextarea
} from '@bigbank/interface-components'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import errorHandler from '../../errors/errorHandler'
import RegistrationMixin from '../common/RegistrationMixin.vue'
import { getCountriesList, prependLocalCountryOption } from '@/util/countries'
import { STATELESS_COUNTRY_CODE } from '../../const'
import { mapActions, mapState } from 'pinia'
import {
  FormValidation,
  isCustomFieldOfActivityRequired,
  isDeepTINValidationRequired,
  isTINRequired,
  getTINInputMask,
  getFieldsOfActivityByChannel,
  isTaxResidencyFieldShownAndRequired
} from '@bigbank/dc-common/validators/validation.config'
import { CBValidator } from '@bigbank/dc-common/validators/cb/CBValidator.class'
import { CountryChannel, FieldOfActivity } from '@bigbank/dc-common/config'
import { useAppStore } from '@/store/app.store'

dayjs.extend(customParseFormat)

const cbValidator = new CBValidator()

export default {
  name: 'additional-details',
  components: {
    BbApplicationPage,
    BbPublicHeader,
    BbInput,
    BbCheckbox,
    BbRadio,
    BbSelect,
    BbMaskedInput,
    BbTextarea
  },
  mixins: [
    RegistrationMixin
  ],
  props: {
    /**
     * Example:
     * [
     *   {
     *     name: 'taxIdNumber',
     *     showField: form => form.taxResidency !== this.channel
     *     validator: { message: 'Wrong value', validate: value => {...} }
     *   }
     * ]
     *
     * Field names:
     *   dateOfBirth, placeOfBirth, taxIdNumber, taxResidency,
     *   fieldOfActivity, customFieldOfActivity, citizenship,
     *   dualCitizenship, isPep
     */
    headerText: {
      type: String,
      required: false
    },
    fieldDefinitions: {
      type: Array,
      required: true
    },
    dateFormat: {
      type: [String, Array],
      required: true
    },
    channel: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    updateFormData: {
      type: Function,
      required: true
    },
    goToNextStep: {
      type: Function,
      required: true
    },
    isLastStep: {
      type: Boolean,
      default: false
    },
    getStatus: {
      type: Function
    },
    register: {
      type: Function
    }
  },
  data () {
    return {
      isLoading: true,
      hasDualCitizenship: false,
      form: {
        dateOfBirth: undefined,
        placeOfBirth: undefined,
        taxIdNumber: undefined,
        taxResidency: undefined,
        fieldOfActivity: undefined,
        customFieldOfActivity: undefined,
        citizenship: undefined,
        dualCitizenship: undefined,
        isPep: undefined,
        isPepReason: undefined
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['status', 'formData', 'countries', 'isV2Flow']),
    commonInitialValues () {
      const customer = this.status?.customer
      const channel = this.channel.toUpperCase()

      return [
        {
          name: 'citizenship',
          value: customer?.citizenship,
          // First check can be removed after new backend is released
          isDisabled: !!(this.isV2Flow && customer?.citizenship && channel === 'FI') ||
            !!this.status.prefilledNonEditableFormFields?.includes('citizenship')
        },
        {
          name: 'dualCitizenship',
          value: customer?.dualCitizenship ?? customer?.dualCitizenCountryCode, // <- dualCitizenCountryCode can be removed after new backend is released
          // First check can be removed after new backend is released
          isDisabled: !!(this.isV2Flow && customer?.dualCitizenCountryCode && channel === 'FI') ||
            !!this.status.prefilledNonEditableFormFields?.includes('dualCitizenship')
        },
        {
          name: 'placeOfBirth',
          value: customer?.placeOfBirth,
          isDisabled: !!this.status.prefilledNonEditableFormFields?.includes('placeOfBirth')
        },
        {
          name: 'taxResidency',
          value: customer?.taxResidency,
          isDisabled: !!this.status.prefilledNonEditableFormFields?.includes('taxResidency')
        }
      ]
    },
    headingText () {
      return this.headerText || this.translations.header
    },
    countryOptions () {
      const countryList = getCountriesList(this.locale, this.countries)

      return prependLocalCountryOption(countryList, this.channel)
    },
    dualCitizenshipCountryOptions () {
      if (this.form.citizenship) {
        return this.countryOptions.filter(country => country.value !== this.form.citizenship)
      }
      return this.countryOptions
    },
    countryOptionsWithExcludedStateless () {
      const countryList = getCountriesList(this.locale, this.countries, [STATELESS_COUNTRY_CODE])

      return prependLocalCountryOption(countryList, this.channel)
    },
    translations () {
      return {
        header: this.$pgettext('registration', 'Additional Details'),
        submitText: this.$pgettext('registration', 'Continue'),
        dateOfBirth: this.$pgettext('registration', 'Date of Birth'),
        placeOfBirth: this.$pgettext('registration', 'Place of Birth'),
        taxIdNumber: this.$pgettext('registration', 'Tax ID Number'),
        taxResidency: this.$pgettext('registration', 'Tax Residency'),
        taxResidencyHelp: this.$pgettext('registration', 'TAX_RESIDENCY_HELP_TEXT'),
        fieldOfActivity: this.$pgettext('registration', 'Field of Activity'),
        citizenship: this.$pgettext('registration', 'Citizenship'),
        haveDualCitizenship: this.$pgettext('registration', 'I have dual citizenship.'),
        dualCitizenship: this.$pgettext('registration', 'Dual Citizenship'),
        pep: this.$pgettext('registration', 'Are you a PEP?'),
        pepHelp: this.$pgettext('registration', 'PEP_HELP_TEXT'),
        pepHelpLV: this.$pgettext('registration_lv', 'PEP_HELP_TEXT FOR LV ONLY'),
        isPepReason: this.$pgettext('registration', 'PEP Reason'),
        otherActivity: this.$pgettext('registration', 'Other Activity'),
        errors: {
          invalidDateFormat: this.$pgettext('registration', 'Invalid date format'),
          errorText: this.$pgettext('registration', 'FORM_HAS_ERRORS_MESSAGE'),
          INTERNAL_ERROR: this.$pgettext('registration_error', 'Something went wrong, please try again later. If the error persists, please contact as an provide the following error code: #CODE#.'),
          VALIDATION_FAILED: this.$pgettext('registration_error', 'Form validation failed, please double check your data and try again.'),
          dualCitizenship: this.$pgettext('dual_citizenship_error', 'Dual citizenship cannot be the same as citizenship')
        },
        fieldsOfActivity: {
          [FieldOfActivity.PRIVATE_SECTOR]: this.$pgettext('registration', 'Private sector'),
          [FieldOfActivity.PUBLIC_SECTOR]: this.$pgettext('registration', 'Public sector'),
          [FieldOfActivity.ENTREPRENEUR]: this.$pgettext('registration', 'Entrepreneur'),
          [FieldOfActivity.INDIVIDUAL_ACTIVITY]: this.$pgettext('registration', 'Individual activity'),
          [FieldOfActivity.UNEMPLOYED]: this.$pgettext('registration', 'Unemployed'),
          [FieldOfActivity.PENSIONER]: this.$pgettext('registration', 'Pensioner'),
          [FieldOfActivity.STUDENT]: this.$pgettext('registration', 'Student'),
          [FieldOfActivity.PARENTAL_LEAVE]: this.$pgettext('registration', 'Parental leave'),
          [FieldOfActivity.CONSCRIPT]: this.$pgettext('registration', 'Conscript'),
          [FieldOfActivity.OTHER]: this.$pgettext('registration', 'Other')
        }
      }
    },
    pepHelpText () {
      if (this.channel === CountryChannel.LV) {
        return this.translations.pepHelpLV
      } else {
        return this.translations.pepHelp
      }
    },
    dateFormatPlaceholder () {
      if (Array.isArray(this.dateFormat)) {
        return this.dateFormat[0]
      }

      return this.dateFormat
    },
    taxResidency () {
      const is = isTaxResidencyFieldShownAndRequired(FormValidation.OnBoardingFlow, this.channel, null)

      return {
        isVisible: is.visible,
        validationRules: { required: is.required }
      }
    },
    TIN () {
      const isDeepValidationRequired = isDeepTINValidationRequired(FormValidation.OnBoardingFlow, this.channel, this.form.taxResidency)
      const isRequired = isTINRequired(FormValidation.OnBoardingFlow, this.channel, this.form.taxResidency)

      return {
        isVisible: isRequired,
        isMaskUsed: isDeepValidationRequired,
        mask: isDeepValidationRequired ? getTINInputMask(FormValidation.OnBoardingFlow, this.channel) : null,
        validationRules: {
          required: isDeepValidationRequired || isRequired,
          tax_id_number: isDeepValidationRequired
        }
      }
    },
    PEP () {
      return {
        isVisible: this.showField('isPep'),
        validationRules: { required: true },
        options: [{
          value: 'NO',
          text: this.$pgettext('registration', 'No')
        }, {
          value: 'YES',
          text: this.$pgettext('registration', 'Yes')
        }],
        label: this.translations.pep
      }
    },
    customFieldOfActivity () {
      return {
        validationRules: {
          required: isCustomFieldOfActivityRequired(FormValidation.OnBoardingFlow, this.channel, this.form.fieldOfActivity)
        }
      }
    },
    fieldOfActivity () {
      return {
        isClarificationRequired: isCustomFieldOfActivityRequired(FormValidation.OnBoardingFlow, this.channel, this.form.fieldOfActivity),
        validationRules: { required: true },
        options: getFieldsOfActivityByChannel(FormValidation.OnBoardingFlow, this.channel).map(fieldOfActivity => ({
          text: typeof this.translations.fieldsOfActivity === 'object' ? (this.translations.fieldsOfActivity[fieldOfActivity] ?? '') : fieldOfActivity,
          value: fieldOfActivity
        }))
      }
    },
    isDateOfBirthEnabledInAdditionalDetails () {
      return !(this.channel === CountryChannel.LV && this.isV2Flow)
    }
  },
  methods: {
    ...mapActions(useAppStore, ['getCountries']),
    async submitAfterHCaptchaVerify (hCaptchaToken) {
      if (!await this.$validator.validate() || this.isLoading) {
        return false
      }

      this.isLoading = true

      try {
        const additionalDetails = { ...this.form }
        const isPep = additionalDetails.isPep === 'YES'

        if (this.isDateOfBirthEnabledInAdditionalDetails && additionalDetails.dateOfBirth) {
          additionalDetails.dateOfBirth = dayjs(additionalDetails.dateOfBirth, this.dateFormat, true).format('YYYY-MM-DD')
        } else if (!this.isDateOfBirthEnabledInAdditionalDetails) {
          delete additionalDetails.dateOfBirth
        }
        if (this.formData.dateOfBirth && !additionalDetails.dateOfBirth) {
          additionalDetails.dateOfBirth = this.formData.dateOfBirth
        }

        additionalDetails.isPep = isPep
        additionalDetails.isPepReason = isPep ? additionalDetails.isPepReason : undefined
        await this.updateFormData({ additionalDetails })

        if (this.isLastStep) {
          await this.register()
          await this.getStatus()
        } else {
          await this.partialRegistration(this.formData, hCaptchaToken)
        }

        await this.goToNextStep()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    },
    onTaxResidencyChange () {
      this.$emit('tax-residency-change', this.form.taxResidency)
    },
    init () {
      this.form = {
        ...this.form,
        placeOfBirth: !this.fieldConfig('placeOfBirth').disablePrefill ? this.channel : this.form.placeOfBirth,
        taxResidency: !this.fieldConfig('taxResidency').disablePrefill ? this.channel : this.form.taxResidency,
        citizenship: !this.fieldConfig('citizenship').disablePrefill && !this.form.citizenship ? this.channel : this.form.citizenship,
        isPep: this.initialValue('isPep', null)
      }
      this.hasDualCitizenship = !!this.form.dualCitizenship
    },
    onCitizenshipChange () {
      if (this.hasDualCitizenship) {
        this.form.dualCitizenship = null
      }
    }
  },
  watch: {
    'form.taxResidency' () {
      const isTaxIdNumberFilled = (this.form.taxIdNumber ?? '').trim().length > 0

      if (this.TIN.validationRules.required && isTaxIdNumberFilled) {
        setTimeout(() => this.$validator.validate('taxIdNumber'), 1000)
      }
    },
    form: {
      deep: true,
      handler (value) {
        const isUSACustomer = [
          value?.placeOfBirth,
          value?.taxResidency,
          value?.citizenship,
          value?.dualCitizenship
        ].some(el => el === 'US')

        this.$emit('toggleFooterBanner', isUSACustomer)
      }
    }
  },
  async created () {
    this.addValidatorRules()

    this.$validator.extend('date_format', {
      getMessage: () => this.translations.errors.invalidDateFormat,
      validate: date => {
        const dayJsDate = dayjs(date, this.dateFormat, true)
        return dayJsDate && dayJsDate.isValid() && dayJsDate.isBefore(dayjs())
      }
    })

    this.$validator.extend('tax_id_number', {
      validate: value => {
        if (isDeepTINValidationRequired(FormValidation.OnBoardingFlow, this.channel, this.form.taxResidency)) {
          return cbValidator.isValidTaxIdNumber(value, {
            forceChannel: this.form.taxResidency
          })
        }
        if (isTINRequired(FormValidation.OnBoardingFlow, this.channel, this.form.taxResidency)) {
          return (value ?? '').trim().length > 0
        }

        return true
      }
    })

    this.$validator.extend('dual_citizenship', {
      getMessage: () => this.translations.errors.dualCitizenship,
      validate: value => {
        if (this.hasDualCitizenship) {
          return this.form.citizenship !== value
        }
        return true
      }
    })

    try {
      await this.getCountries()
      this.init()
      this.isLoading = false
    } catch (err) {
      errorHandler(err)
    }
  }
}
</script>

<style lang="scss" scoped>
.formOrder {
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
.bb-textarea.is-pep-reason {
  label {
    white-space: normal !important;
    overflow: auto !important;
  }
}
</style>
