<template>
  <bb-application-page
      :form-props="{
        uppercaseButtons: false,
        errorText: translations.errors.errorText,
        submitText: translations.submitText,
        disabled: isLoading,
        loading: isLoading,
      }"
      @submit="onSubmit"
  >
    <bb-public-header
        #header
        :title="translations.contactAddressHeader"
        icon="client-contact"
    />
    <p v-if="errorMessage" class="f-color-red f-align-center f-gotham f-small m-t-10 m-b-20">
      {{ errorMessage }}
    </p>
    <bb-input
        v-model="form.contactAddress.streetAddress"
        v-validate="'required|input_max_length:200'"
        :label="translations.contactStreetAddress"
        :data-vv-as="translations.contactStreetAddress"
        name="contactStreetAddress"
        :mandatory="true"
    />
    <bb-input
        v-model="form.contactAddress.city"
        v-validate="'required|input_max_length:100'"
        :label="translations.contactCity"
        :data-vv-as="translations.contactCity"
        name="contactCity"
        :mandatory="true"
    />
    <bb-input
        v-model="form.contactAddress.county"
        v-validate="'required|input_max_length:100'"
        :label="translations.contactCounty"
        :data-vv-as="translations.contactCounty"
        name="contactCounty"
        :mandatory="true"
    />
    <bb-input
        v-model="form.contactAddress.postcode"
        @input="handleContactPostcodeChange"
        v-validate="'required|ee_contact_postal_code|input_max_length:30'"
        :label="translations.contactPostalCode"
        :data-vv-as="translations.contactPostalCode"
        name="contactPostalCode"
        :mandatory="true"
    />
    <bb-select
        v-model="form.contactAddress.country"
        v-validate="'required'"
        :options="countryOptionsWithExcludedStateless"
        :label="translations.contactCountry"
        :data-vv-as="translations.contactCountry"
        @change="onContactCountryChange"
        name="contactCountry"
        :mandatory="true"
    />

    <bb-public-header
        :title="translations.additionalDetailsHeader"
        icon="client-details"
    />
    <bb-select
        v-model="form.education"
        v-validate="'required'"
        :options="education"
        :label="translations.education"
        :data-vv-as="translations.education"
        name="education"
        :mandatory="true"
    />
    <bb-select
        v-model="form.fieldOfActivity"
        v-validate="'required'"
        :options="fieldOfActivity"
        :label="translations.fieldOfActivity"
        :data-vv-as="translations.fieldOfActivity"
        name="fieldOfActivity"
        :mandatory="true"
    />
    <bb-select
        v-if="showActivityField"
        v-model="form.activity"
        :options="activities"
        :label="translations.activity"
        :data-vv-as="translations.activity"
        name="activity"
        :mandatory="true"
        v-validate="'required'"
    />
    <bb-input
        v-if="showEmployerField"
        v-model="form.employer"
        v-validate.immediate="'required|input_max_length:300'"
        :label="translations.employer"
        :data-vv-as="translations.employer"
        name="employer"
        :mandatory="true"
    />
  </bb-application-page>
</template>

<script>
import RegistrationMixin from '../../common/RegistrationMixin.vue'
import { cleanInput } from '../../common/util'
import {
  BbApplicationPage,
  BbPublicHeader,
  BbInput,
  BbSelect
} from '@bigbank/interface-components'
import { mapActions, mapState } from 'pinia'
import errorHandler from '@/errors/errorHandler'
import { STATELESS_COUNTRY_CODE } from '@/const'
import { EEValidator } from '@bigbank/dc-common/validators/ee/EEValidator.class'
import '@bigbank/interface-components/dist/svg/client/details'
import { Activity, Education } from '@bigbank/dc-common/clients/http/crm-service/crm-service.enums'
import { FieldOfActivity } from '@bigbank/dc-common/config'
import { FormValidation, getFieldsOfActivityByChannel } from '@bigbank/dc-common/validators/validation.config'
import { useAppStore } from '@/store/app.store'

const eeValidator = new EEValidator()

export default {
  name: 'ee-address-and-background-details',
  components: {
    BbApplicationPage,
    BbPublicHeader,
    BbInput,
    BbSelect
  },
  mixins: [
    RegistrationMixin
  ],
  data () {
    return {
      isLoading: true,
      form: {
        education: undefined,
        activity: undefined,
        fieldOfActivity: undefined,
        employer: undefined,
        contactAddress: {
          streetAddress: undefined,
          city: undefined,
          county: undefined,
          postcode: undefined,
          country: 'EE'
        }
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'formData', 'countries']),
    translations () {
      return {
        contactAddressHeader: this.$pgettext('customer_data_sheet', 'Contact Address'),
        contactStreetAddress: this.$pgettext('customer_data_sheet', 'Mailing Street & house'),
        contactCity: this.$pgettext('customer_data_sheet', 'Mailing City'),
        contactCounty: this.$pgettext('customer_data_sheet', 'County'),
        contactPostalCode: this.$pgettext('customer_data_sheet', 'Mailing Postal code'),
        contactCountry: this.$pgettext('customer_data_sheet', 'Mailing Country'),
        additionalDetailsHeader: this.$pgettext('customer_data_sheet', 'Additional Details'),
        education: this.$pgettext('customer_data_sheet', 'Education'),
        fieldOfActivity: this.$pgettext('customer_data_sheet', 'Field of Activity'),
        activity: this.$pgettext('customer_data_sheet', 'Activity'),
        employer: this.$pgettext('customer_data_sheet', 'Employer'),
        submitText: this.$pgettext('customer_data_sheet', 'SUBMIT_FORM_BUTTON_TEXT'),
        errors: {
          invalidPostalCode: this.$pgettext('customer_data_sheet', 'Postal code has invalid format'),
          errorText: this.$pgettext('customer_data_sheet', 'FORM_HAS_ERRORS_MESSAGE')
        },
        educations: {
          [Education.Basic]: this.$pgettext('customer_data_sheet', 'Basic'),
          [Education.SecondarySpecialised]: this.$pgettext('customer_data_sheet', 'Secondary specialised'),
          [Education.Secondary]: this.$pgettext('customer_data_sheet', 'Secondary'),
          [Education.Higher]: this.$pgettext('customer_data_sheet', 'Higher'),
          [Education.HigherBA]: this.$pgettext('customer_data_sheet', 'Higher BA'),
          [Education.HigherMA]: this.$pgettext('customer_data_sheet', 'Higher MA'),
          [Education.HigherPHD]: this.$pgettext('customer_data_sheet', 'Higher PHD')
        },
        fieldsOfActivity: {
          [FieldOfActivity.PRIVATE_SECTOR]: this.$pgettext('customer_data_sheet', 'Private sector'),
          [FieldOfActivity.INDIVIDUAL_ACTIVITY]: this.$pgettext('customer_data_sheet', 'Individual activity'),
          [FieldOfActivity.ENTREPRENEUR]: this.$pgettext('customer_data_sheet', 'Entrepreneur'),
          [FieldOfActivity.UNEMPLOYED]: this.$pgettext('customer_data_sheet', 'Unemployed'),
          [FieldOfActivity.PENSIONER]: this.$pgettext('customer_data_sheet', 'Pensioner'),
          [FieldOfActivity.STUDENT]: this.$pgettext('customer_data_sheet', 'Student'),
          [FieldOfActivity.PARENTAL_LEAVE]: this.$pgettext('customer_data_sheet', 'Parental leave'),
          [FieldOfActivity.PUBLIC_SECTOR]: this.$pgettext('customer_data_sheet', 'Public servant')
        },
        activities: {
          [Activity.Worker]: this.$pgettext('customer_data_sheet', 'Worker'),
          [Activity.Specialist]: this.$pgettext('customer_data_sheet', 'Specialist'),
          [Activity.SeniorSpecialist]: this.$pgettext('customer_data_sheet', 'Senior specialist'),
          [Activity.TeamLead]: this.$pgettext('customer_data_sheet', 'Team lead'),
          [Activity.MiddleManager]: this.$pgettext('customer_data_sheet', 'Middle manager'),
          [Activity.SeniorManager]: this.$pgettext('customer_data_sheet', 'Senior manager')
        }
      }
    },
    showActivityField () {
      return [
        FieldOfActivity.PRIVATE_SECTOR,
        FieldOfActivity.PUBLIC_SECTOR
      ].includes(this.form.fieldOfActivity)
    },
    showEmployerField () {
      return [
        FieldOfActivity.PRIVATE_SECTOR,
        FieldOfActivity.PUBLIC_SECTOR,
        FieldOfActivity.ENTREPRENEUR
      ].includes(this.form.fieldOfActivity)
    },
    countryOptionsWithExcludedStateless () {
      return this.getCountriesList([STATELESS_COUNTRY_CODE])
    },
    activities () {
      return Object.values(Activity).map(activity => ({
        text: this.translations.activities[activity] ?? activity,
        value: activity
      }))
    },
    education () {
      return Object.values(Education).map(education => ({
        text: this.translations.educations[education] ?? education,
        value: education
      }))
    },
    fieldOfActivity () {
      return getFieldsOfActivityByChannel(FormValidation.CurrentAccountOnboarding, this.channel).map(fieldOfActivity => ({
        text: this.translations.fieldsOfActivity[fieldOfActivity] ?? fieldOfActivity,
        value: fieldOfActivity
      }))
    }
  },
  methods: {
    ...mapActions(useAppStore, ['getStatus', 'updateFormData', 'goToNextStep', 'getCountries']),
    getCountriesList (excludeByCode) {
      let countries = this.countries.map(country => ({
        value: country.countryCode,
        text: country.translation
      }))
        .sort((a, b) => a.text.localeCompare(b.text, this.locale))

      if (Array.isArray(excludeByCode)) {
        countries = countries.filter((country) => !excludeByCode.includes(country.value))
      }

      return countries
    },
    async onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true

      if (!this.showActivityField) {
        this.form.activity = undefined
      }

      if (!this.showEmployerField) {
        this.form.employer = undefined
      }

      try {
        await this.updateFormData({ addressAndBackground: this.form })
        await this.partialRegistration(this.formData)
        await this.goToNextStep()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    },
    handleContactPostcodeChange (value) {
      this.form.contactAddress.postcode = cleanInput(value)
    },
    onContactCountryChange () {
      this.$validator.validate('contactPostalCode')
    },
    prefillInitialValues () {
      this.form.activity = this.status.customer?.activity
      this.form.education = this.status.customer?.education
      if (this.status.customer?.employer) {
        this.form.employer = this.status.customer?.employer
      }
      if (this.fieldOfActivity.some(item => item.value === this.status.customer?.fieldOfActivity)) {
        this.form.fieldOfActivity = this.status.customer?.fieldOfActivity
      }
      if (this.status.customer?.contactAddress?.country) {
        this.form.contactAddress.country = this.status.customer?.contactAddress.country
      }
      if (this.status.customer?.contactAddress?.postcode) {
        this.form.contactAddress.postcode = this.status.customer?.contactAddress.postcode
      }
      if (this.status.customer?.contactAddress?.streetAddress) {
        this.form.contactAddress.streetAddress = this.status.customer?.contactAddress.streetAddress
      }
      if (this.status.customer?.contactAddress?.county) {
        this.form.contactAddress.county = this.status.customer?.contactAddress.county
      }
      if (this.status.customer?.contactAddress?.city) {
        this.form.contactAddress.city = this.status.customer?.contactAddress.city
      }
    },
    errorTranslationsPicker (fieldName) {
      return {
        [this.translations.contactStreetAddress]: this.$pgettext('customer_data_sheet', 'Entered street is faulty'),
        [this.translations.contactCity]: this.$pgettext('customer_data_sheet', 'Entered city is faulty'),
        [this.translations.contactCounty]: this.$pgettext('customer_data_sheet', 'Entered county is faulty'),
        [this.translations.contactPostalCode]: this.$pgettext('customer_data_sheet', 'Entered postal index is faulty'),
        [this.translations.employer]: this.$pgettext('customer_data_sheet', 'Entered employer is faulty')
      }[fieldName]
    }
  },
  async created () {
    window.scrollTo(0, 0)
    try {
      this.$validator.extend('ee_contact_postal_code', {
        getMessage: () => this.translations.errors.invalidPostalCode,
        validate: value => {
          if (this.form.contactAddress.country !== 'EE') {
            return this.form.contactAddress.postcode.length >= 3
          }

          return eeValidator.isValidPostalCode(value)
        }
      })

      this.$validator.extend('input_max_length', {
        getMessage: (field) => this.errorTranslationsPicker(field),
        validate: (value, { maxLength }) => value.toString().length < maxLength,
        paramNames: ['maxLength']
      })

      await this.getCountries()
      this.prefillInitialValues()
      this.isLoading = false
    } catch (err) {
      errorHandler(err)
    }
  }
}
</script>
